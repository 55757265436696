<main class="container-fluid px-0">
  <div class="g-pa-20">

    <div class="row">


      <div class="col-lg-8">
        <app-mvp-timer></app-mvp-timer>
      </div>
     
      <div class="col-lg-4">
        <app-mvp-info></app-mvp-info>
      </div>

    </div>
  </div>
</main>