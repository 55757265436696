import { Injectable } from '@angular/core';
import { Timer } from './Timer';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class TimerService {

    private storageSub = new Subject<String>();

    constructor() { }

    getTimers(): Timer[] {
        return JSON.parse(localStorage.getItem("timers")) as Timer[];
    }

    watchStorage(): Observable<any> {
        return this.storageSub.asObservable();
    }
    init(): void {
        if (this.getTimers().length <= 0) { this.saveTimer(null) }
    }

    saveTimer(timer: Timer): void {
        let timerList = this.getTimers() || [] as Timer[];

        let index = timerList.findIndex(obj => obj.mvpId === timer.mvpId && obj.map === timer.map);
        //console.log(timerList, index)
        if (index >= 0) {
            timerList[index] = timer;
        } else {
            timerList.push(timer);
        }
        localStorage.setItem("timers", JSON.stringify(timerList))

        this.storageSub.next('changed');
    }

    deleteTimer(timer: Timer): void {
        let timerList = this.getTimers() || [] as Timer[];

        let index = timerList.findIndex(obj => obj.mvpId === timer.mvpId && obj.map === timer.map);
        console.log(timerList, index)
        if (index >= 0) {
            timerList.splice(index, 1);
        }
        localStorage.setItem("timers", JSON.stringify(timerList))

        this.storageSub.next('changed');
    }

    clearAllTimers(): void {
        localStorage.clear();
        this.storageSub.next('changed');
    }
}

