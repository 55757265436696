<div class="card g-brd-gray-light-v7 g-mb-30 u-card-v1 g-rounded-3">
    <header class="media g-pa-15 g-pa-25-30-0--md g-mb-20">
        <div class="media-body align-self-center">
            <h3 class="text-uppercase g-font-size-default g-color-black g-mb-8">Next MVPs
            </h3>
        </div>

        <div class="d-flex align-self-end align-items-center">
            <span class="g-line-height-1 g-font-weight-300 g-font-size-28 g-color-secondary">{{currentDate | date :'HH:mm:ss'}}</span>
            <span class=" g-pa-15 g-line-height-1 g-font-weight-300 g-font-size-28 g-color-primary">Next: {{nextDate | date :'HH:mm:ss'}}</span>
        </div>
    </header>

    <div class=" g-pa-15 g-pa-0-30-25--md">

        <table class="table table-responsive-sm w-100">
            <thead>
                <tr>
              
                </tr>
            </thead>
            <tbody>
                <tr app-mvp-timer-row *ngFor="let timer of timers" [timer]="timer">
                </tr>
        </tbody>
        </table>
    </div>
</div>