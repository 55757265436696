import { Component, OnInit, Input } from '@angular/core';
import { Mvp } from '../data/mvp/Mvp';
import { TimerService } from '../data/timer/timer.service';
import { Timer } from '../data/timer/Timer';

@Component({
  selector: 'tr[app-mvp-info-row]',
  templateUrl: './mvp-info-row.component.html',
  styleUrls: ['./mvp-info-row.component.scss']
})
export class MvpInfoRowComponent implements OnInit {

  @Input()
  mvp: Mvp;

  color: string = 'g-brd-teal-v2 g-bg-teal-v2';

  constructor(private timerService: TimerService) { }

  ngOnInit(): void {

    if (this.mvp.cooldownInMinutes) {
      if (this.mvp.cooldownInMinutes > 5) {
        this.color = 'g-brd-lightred-v2 g-bg-lightred-v2';
      } else {
        this.color = 'g-brd-lightyellow g-bg-lightyellow '
      }

    }
  }

  saveTimer(mvpId:number, map: string, comment?: string) {
    const newTimer: Timer = { mvpId: mvpId, dead: new Date(), comment: comment , map: map };
    this.timerService.saveTimer(newTimer);
    window.scroll(0,0);
  }

}
