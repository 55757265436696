import { Component, OnInit } from '@angular/core';
import { MvpService } from '../data/mvp/mvp.service';
import { Mvp } from '../data/mvp/Mvp';
import { TimerService } from '../data/timer/timer.service';
import { Timer } from '../data/timer/Timer';

@Component({
  selector: 'app-mvp-info',
  templateUrl: './mvp-info.component.html',
  styleUrls: ['./mvp-info.component.scss']
})
export class MvpInfoComponent implements OnInit {
  mvps: Mvp[];
  timers: Timer[];
  someConnectionHasDetails: boolean;
  currentDate: Date = new Date();
  constructor(private mvpService: MvpService, private timerService: TimerService) {
  }

  ngOnInit(): void {
    this.getMvps();
  }

  getMvps() {
    this.mvpService.getAllMvpsObservable().subscribe((mvps: Mvp[]) => {
      this.mvps = mvps;
    });
  }

}
