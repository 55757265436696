import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { MvpInfoComponent } from './mvp-info/mvp-info.component';
import { MvpTimerComponent } from './mvp-timer/mvp-timer.component';
import { MvpService } from './data/mvp/mvp.service';
import { HttpClientModule } from '@angular/common/http';
import { MvpInfoRowComponent } from './mvp-info-row/mvp-info-row.component';
import { MvpTimerRowComponent } from './mvp-timer-row/mvp-timer-row.component';
import { TimerService } from './data/timer/timer.service';

@NgModule({
  declarations: [
    AppComponent,
    MvpInfoComponent,
    MvpTimerComponent,
    MvpInfoRowComponent,
    MvpTimerRowComponent
  ],
  imports: [
    BrowserModule, 
    HttpClientModule
  ],
  providers: [MvpService, TimerService],
  bootstrap: [AppComponent]
})
export class AppModule { }
