<td
    class="text-center g-font-size-default g-color-black g-valign-middle g-brd-top-none g-brd-bottom g-brd-2 g-brd-gray-light-v4 g-py-10 g-pl-20">
    <a href="https://cp.originsro.org/monster/view/?id={{mvp.id}}" target="_blank">
        <img src="https://cp.originsro.org/data/images/monsters/{{mvp.id}}.gif"
            style="max-width:80px;max-height:80px;" />
    </a>
</td>
<td class="g-font-size-default g-color-black g-valign-middle g-brd-top-none g-brd-bottom g-brd-2 g-brd-gray-light-v4 g-py-10 g-pl-20">
    {{mvp.name}}<br>
    <small>{{mvp.map}}</small>
</td>

<td class="g-font-size-default g-color-black g-valign-middle g-brd-top-none g-brd-bottom g-brd-2 g-brd-gray-light-v4 g-py-10">
    <span class="badge badge-success">{{mvp.cooldownInMinutes}} min</span>
</td>
<td  class="g-font-size-default g-color-black g-valign-middle g-brd-top-none g-brd-bottom g-brd-2 g-brd-gray-light-v4 g-py-10">
<button class="btn btn-primary" (click)="saveTimer(mvp.id, mvp.map)">DEAD!</button>   
</td>