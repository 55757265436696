<div class="card g-brd-gray-light-v7 g-mb-30 u-card-v1 g-rounded-3">
    <header class="media g-pa-15 g-pa-25-30-0--md g-mb-20">
        <div class="media-body align-self-center">
            <h3 class="text-uppercase g-font-size-default g-color-black g-mb-8">All MVPs
            </h3>
        </div>
    </header>

    <div class=" g-pa-15 g-pa-0-30-25--md">

        <table class="table table-responsive-sm w-100">
            <thead>
                <tr>
                
                </tr>
            </thead>
            <tbody>
                <tr app-mvp-info-row *ngFor="let mvp of mvps" [mvp]="mvp">
                </tr>
        </tbody>
        </table>
    </div>
</div>