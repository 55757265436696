import { Component, OnInit, Input } from '@angular/core';
import { Mvp } from '../data/mvp/Mvp';
import { Timer } from '../data/timer/Timer';
import { TimerService } from '../data/timer/timer.service';

@Component({
  selector: 'tr[app-mvp-timer-row]',
  templateUrl: './mvp-timer-row.component.html',
  styleUrls: ['./mvp-timer-row.component.scss']
})
export class MvpTimerRowComponent implements OnInit {

  @Input()
  timer: Timer;
  nextSpawn: Date;
  nextSpawnMinutes: number;
  math = Math;

  color: string = 'g-brd-teal-v2 g-bg-teal-v2';

  constructor(private timerService: TimerService) { }

  ngOnInit(): void {
    this.nextSpawn = new Date(new Date(this.timer.dead).getTime() + this.timer.mvpInfo.cooldownInMinutes * 60000);
    this.nextSpawnMinutes = Math.round((new Date(this.nextSpawn).getTime() - new Date().getTime()) / 60000);

    if (this.nextSpawnMinutes == 5) {
      Notification.requestPermission();
      var notification = new Notification(`${this.timer.mvpInfo.name} spawns soon`, { body: `get ready and warp to ${this.timer.mvpInfo.map}` });
      setTimeout(function () {
        notification.close();
      }, 10000);
    }
  }


  plus() {
    this.timer.dead = new Date(new Date(this.timer.dead).getTime() + 1 * 60000);
    this.timerService.saveTimer(this.timer)
  }
  minus() {
    this.timer.dead = new Date(new Date(this.timer.dead).getTime() - 1 * 60000);
    this.timerService.saveTimer(this.timer)
  }


  plus_fifteen() {
    this.timer.dead = new Date(new Date(this.timer.dead).getTime() + 15 * 60000);
    this.timerService.saveTimer(this.timer)
  }
  minus_fifteen() {
    this.timer.dead = new Date(new Date(this.timer.dead).getTime() - 15 * 60000);
    this.timerService.saveTimer(this.timer)
  }

  saveTimer() {
    this.timer.dead = new Date();
    this.timerService.saveTimer(this.timer)
  }

  deleteTimer() {
    console.log("deleting timer", this.timer);
    this.timerService.deleteTimer(this.timer)
  }

}
