<td
    class="text-center g-font-size-default g-color-black g-valign-middle g-brd-top-none g-brd-bottom g-brd-2 g-brd-gray-light-v4 g-py-10 g-pl-20">
    <a href="https://cp.originsro.org/monster/view/?id={{timer.mvpId}}" target="_blank">
        <img src="https://cp.originsro.org/data/images/monsters/{{timer.mvpId}}.gif"
            style="max-width:80px;max-height:80px;" />
    </a>
</td>
<td
    class="g-font-size-default g-color-black g-valign-middle g-brd-top-none g-brd-bottom g-brd-2 g-brd-gray-light-v4 g-py-10 g-pl-20">
    {{timer.mvpInfo.name}}<br>
    <small> {{timer.map}}</small>
</td>

<td
    class="g-font-size-default g-color-black g-valign-middle g-brd-top-none g-brd-bottom g-brd-2 g-brd-gray-light-v4 g-py-10 g-pl-20">
    <div class="js-quantity input-group u-quantity--v2 g-width-200 g-brd-none">
      
        <span class="btn btn-default" (click)="minus_fifteen()" title="-15m" >--</span>
        <span class="btn btn-default" (click)="minus()" >-</span>
        <input class="js-result form-control g-color-gray-dark-v6 rounded-0 p-0" type="text" value="{{timer.dead | date :'HH:mm'}}" readonly="">
        <span class="btn btn-default" (click)="plus()" >+</span>
        <span class="btn btn-default" (click)="plus_fifteen()" title="+15m" >++</span>
      </div>
</td>

<td class="g-valign-middle g-brd-top-none g-brd-bottom g-brd-2 g-brd-gray-light-v4 g-py-10" title="{{nextSpawn | date:'HH:mm'}}">
    
    <span *ngIf="nextSpawnMinutes > 15" class="badge badge-success">{{nextSpawnMinutes}} min</span>
    <span *ngIf="nextSpawnMinutes <= 15 && nextSpawnMinutes > 5" class="badge badge-warning">{{nextSpawnMinutes}} min</span>
    <span *ngIf="nextSpawnMinutes <= 5 && nextSpawnMinutes > 0" class="badge badge-danger">{{nextSpawnMinutes}} min</span>
    <span *ngIf="nextSpawnMinutes == 0" class="badge badge-danger">OUT </span>
    <span *ngIf="nextSpawnMinutes < 0" class="badge badge-danger">OUT since {{this.math.abs(nextSpawnMinutes)}} min</span>

</td>

<td
    class="g-font-size-default g-color-black g-valign-middle g-brd-top-none g-brd-bottom g-brd-2 g-brd-gray-light-v4 g-py-10">
    <button class="btn btn-block btn-primary" (click)="saveTimer()">DEAD!</button>
</td>

<td
    class="g-font-size-default g-color-black g-valign-middle g-brd-top-none g-brd-bottom g-brd-2 g-brd-gray-light-v4 g-py-10 hidden-xs">
    <button class="btn btn-default" (click)="deleteTimer()">x</button> 
</td>