import { Component, OnInit } from '@angular/core';
import { MvpService } from '../data/mvp/mvp.service';
import { Mvp } from '../data/mvp/Mvp';
import { TimerService } from '../data/timer/timer.service';
import { Timer } from '../data/timer/Timer';

@Component({
  selector: 'app-mvp-timer',
  templateUrl: './mvp-timer.component.html',
  styleUrls: ['./mvp-timer.component.scss']
})
export class MvpTimerComponent implements OnInit {
  timers: Timer[];
  currentDate: Date = new Date();
  nextDate: Date;
  mvps: Mvp[];
  constructor(private timerService: TimerService, private mvpService: MvpService) {
  }

  ngOnInit(): void {
    this.mvpService.getAllMvpsObservable().subscribe((mvps: Mvp[]) => {
      this.mvps = mvps;
      this.getNextTimers();

      this.timerService.watchStorage().subscribe((data: string) => {
        this.getNextTimers();
      })

    });


    let i: number = 0;
    setInterval(() => {
      console.log(i);
      this.currentDate = new Date();
      if (i % 30 === 0) {
        this.getNextTimers();
      }
      i++;

    }, 1000)
  }

  getNextTimers() {
    this.timers = this.timerService.getTimers()
    if (this.timers) {
      this.timers.forEach(timer => {
        timer.mvpInfo = this.mvps.find(i => i.id === timer.mvpId);
      });

      const calculateNextSpawn = function (timer: Timer) {
        return new Date(new Date(timer.dead).getTime() + timer.mvpInfo.cooldownInMinutes * 60000)
      }

      //this.timers.sort((a, b) => (calculateNextSpawn(a) > calculateNextSpawn(b)) ? 1 : -1);
      
      const nextDates = this.timers.map(t => calculateNextSpawn(t))
      nextDates.sort()
      //console.log("nextDates",nextDates)
      this.nextDate = nextDates[0];

      const filterOutOld = function (timer: Timer) {
        let nextSpawn: Date = calculateNextSpawn(timer);
        //console.log("next spawn time",(nextSpawn.getTime() > new Date().getTime()));
        return (new Date().getTime()- nextSpawn.getTime() ) / 60000 < 15;
      }

      this.timers = this.timers.filter(filterOutOld);
      
    }
  }

}
