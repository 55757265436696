import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Mvp } from './Mvp';
import { Observable } from 'rxjs';

@Injectable()
export class MvpService {

    mvpServiceUrl: string = "/assets/data/mvps.json";

    httpOptions: Object = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private http: HttpClient) { }

    getAllMvpsObservable(): Observable<Mvp[]> {
        return this.http.get<Mvp[]>(this.mvpServiceUrl);
    }

}

